import React, { Component, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import queryString from 'querystring';
import SearchPage from './SearchPage';

export default function (props: any) {
  const url = `https://platform-kibana.1011.co.kr/platform-report/_search`;

  function link(v: any) {
    const data = v;
    
    return `/search/${data.name}/${data.date}`;
  }

  function transform(x: any) {
    delete x.id;
    delete x.version;
    delete x.defaultMapping;

    return x;
  }

  return <SearchPage url={url} link={link} transform={transform} defaultSort={[{"date.keyword": "desc"}]} />;
}