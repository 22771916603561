import React, {Component, useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Axios from 'axios';
import _, {orderBy} from 'lodash';
import {useHistory} from "react-router-dom";
import {useTable, usePagination, useSortBy} from 'react-table';
import {Chart} from "react-google-charts";

import xlsx from 'xlsx';

function getValue(obj: any, path: string): any {
    if (!path) {
        return obj;
    }

    const [r, ...rs] = path.split('.');

    return getValue(obj[r], rs.join('.'));
}

function getFields(s: any, n: string = "", r: any[] = []) {
    if (s.type === "text" || s.type === "long" || s.type === "double" || s.type === "boolean") {
        r.push({
            name: n,
            type: s.type,
        });
    } else {
        for (let k in s.properties) {
            if (k.startsWith("_")) {
                continue;
            }

            const ns = [n, k].filter(x => x).join('.');

            r.push(...getFields(s.properties[k], ns));
        }
    }

    return r;
}

function RenderChart(props: any) {
    const {schema: originalSchema, mapping, url, query} = props;

    const schema = originalSchema
        .map((x: any) => ({[x.name]: x.type}))
        .reduce((a: any, b: any) => Object.assign(a, b), {});

    const [open, setOpen] = useState() as any;
    const [types, setTypes] = useState([]) as any;
    const [type, setType] = useState() as any;
    const [chart, setChart] = useState() as any;

    const charts = {
        'histogram': async () => {
            let field = mapping[open];

            if (schema[mapping[open]] === "text") {
                field = field + ".keyword";
            }

            if (schema[mapping[open]] === "text") {
                const r = await Axios.post(url, {
                    "aggs": {
                        "values": {
                            "terms": {
                                "field": field,
                                "size": 10,
                            },
                        }
                    },
                    query: query.query,
                });

                const {buckets} = r.data.aggregations.values;

                setChart(
                    <Chart
                        chartType={"Bar"}
                        width={'100%'}
                        height={'300px'}
                        data={[
                            ['key', 'value'],
                            ...buckets.map((x: any) => [x.key, x.doc_count]),
                            // ["기타", r.data.aggregations.values.sum_other_doc_count],
                        ]}
                        options={{
                            legend: {
                                position: 'none',
                            }
                        }}
                    />
                );
            } else {
                const r1 = await Axios.post(url, {
                    "aggs": {
                        "stats": {
                            "stats": {
                                "field": field,
                            },
                        },
                    },
                    query: query.query,
                });

                const {min, max} = r1.data.aggregations.stats;

                console.log(r1.data);

                const r = await Axios.post(url, {
                    "aggs": {
                        "values": {
                            "histogram": {
                                "field": field,
                                "interval": (max - min) / 50,
                            }
                        }
                    },
                    query: query.query,
                });

                console.log(r.data);

                const {buckets} = r.data.aggregations.values;

                setChart(
                    <Chart
                        chartType={"Bar"}
                        width={'100%'}
                        height={'300px'}
                        data={[
                            ['key', 'value'],
                            ...buckets.map((x: any) => [x.key, x.doc_count]),
                        ]}
                        options={{
                            legend: {
                                position: 'none',
                            }
                        }}
                    />
                );
            }
        }
    };

    useEffect(() => {
        if (open) {
            setTypes(Object.keys(charts));
            if (type) {
                // @ts-ignore
                charts[type]();
            }
        }
    }, [open, query]);

    useEffect(() => {
        if (type) {
            // @ts-ignore
            charts[type]();
        }
    }, [type, query])

    if (!schema) {
        return <div></div>;
    }

    let body = undefined;

    if (open) {
        body = (
            <tbody>
            <tr>
                <td>차트종류</td>
                <td colSpan={Object.keys(schema).length} rowSpan={types.length + 1}>
                    {
                        chart
                    }
                </td>
            </tr>
            {
                types.map((t: string) => {
                    if (t == type) {
                        return (
                            <tr>
                                <td onClick={_ => setType(t)} style={{backgroundColor: '#ddd'}}>{t}</td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr>
                                <td onClick={_ => setType(t)}>{t}</td>
                            </tr>
                        );
                    }
                })
            }
            </tbody>
        )
    }

    return (
        <div>
            <h3>차트</h3>
            <table>
                <thead>
                <tr>
                    <th onClick={_ => setOpen(false)}>닫기</th>
                    {
                        Object.keys(mapping).map(x => {
                            if (x == open) {
                                return <th style={{backgroundColor: '#ddd'}} onClick={_ => setOpen(x)}>{x}</th>;
                            } else {
                                return <th onClick={_ => setOpen(x)}>{x}</th>;
                            }
                        })
                    }
                </tr>
                </thead>
                {body}
            </table>
        </div>
    );
}

function FilterItem(props: any) {
    const {schema: originalSchema, value, setValue, update, onRemove} = props;

    const schema = originalSchema
        .map((x: any) => ({[x.name]: x.type}))
        .reduce((a: any, b: any) => Object.assign(a, b), {});

    const [selected, setSelected] = useState(Object.keys(schema)[0]) as any;

    let input = <></>;

    switch (schema[selected]) {
        case 'boolean': {
            input = (
                <input
                    type={"checkbox"}
                    value={value.checked}
                    onChange={(e) => {
                        value.checked = e.target.checked;

                        setValue(value);
                    }}
                    onKeyDown={e => {
                        if (e.keyCode == 13) {
                            update();
                        }
                    }}
                />
            );
            break;
        }
        case 'double':
        case 'long': {
            input = (
                <div style={{display: 'inline-flex', flexGrow: 1}}>
                    <span>min:</span>
                    <input
                        type={"number"}
                        value={value.min}
                        onChange={(e) => {
                            value.min = Number(e.target.value);

                            setValue(value);
                        }}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                update();
                            }
                        }}
                    />
                    <span>max:</span>
                    <input
                        type={"number"}
                        value={value.max}
                        onChange={(e) => {
                            value.max = Number(e.target.value);

                            setValue(value);
                        }}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                update();
                            }
                        }}
                    />
                </div>
            );

            break;
        }
        default: {
            input = (
                <input
                    style={{flexGrow: 1}}
                    type={"text"}
                    value={value.value}
                    onChange={(e) => {
                        value.value = e.target.value;

                        setValue(value);
                    }}
                    onKeyDown={e => {
                        if (e.keyCode == 13) {
                            update();
                        }
                    }}
                />
            );
        }
    }

    useEffect(() => {
        const s = schema[selected];

        value.field = selected;
        value.type = s;

        switch (s.type) {
            case 'number': {
                value.min = undefined;
                value.max = undefined;

                break;
            }
            default: {
                value.value = '';
            }
        }

        setValue(value);
    }, [selected]);

    return (
        <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <button onClick={onRemove}>필터제거</button>
            <select value={selected} onChange={e => setSelected(e.target.value)}>
                {
                    Object.keys(schema).map((x: any) => {
                        return <option key={x} value={x}>{x}</option>;
                    })
                }
            </select>
            {input}
        </div>
    );
}

export default function Implementation(props: any) {
    const history = useHistory();

    const {info, name, date, reportInfo} = props;

    const url = `https://platform-kibana.1011.co.kr/platform-report-${name}-${date}/_search`;

    let [data, setData] = useState([]) as any;
    let [columns, setColumns] = useState([]) as any;
    let [schema, setSchema] = useState() as any;
    let [query, setQuery] = useState("") as any;
    let [totalCount, setTotalCount] = useState(0) as any;
    let [took, setTook] = useState(0) as any;
    let [filter, setFilter] = useState([]) as any;
    let [mapping, setMapping] = useState(reportInfo.defaultMapping) as any;

    const [controlledPageCount, setPageCount] = React.useState(0);

    function addMapping() {
        const rs = (new Date().valueOf()).toString();

        setMapping({
            ...mapping,
            [rs]: "id",
        })
    }

    function addFilter() {
        const v = {
            field: 'id',
            type: 'string',
            value: '',
        };

        setFilter([...filter, v]);
    }

    async function xlsxExport() {
        // const workBook = xlsx.utils.book_new();
        //
        // const sheet = xlsx.utils.json_to_sheet(data);
        //
        // let [begin, end] = sheet['!ref']!.split(':') as any;
        // begin = xlsx.utils.decode_cell(begin);
        // end = xlsx.utils.decode_cell(end);
        //
        // const cell = {
        //     c: end.c + 1,
        //     r: 0,
        // };
        //
        // sheet[xlsx.utils.encode_cell(cell)] = {
        //     v: 'link',
        //     t: 's',
        // };
        //
        // for (let i = 1; i <= end.r; ++i) {
        //     cell.r = i;
        //
        //     const a = xlsx.utils.encode_cell(cell);
        //
        //     sheet[a] = {
        //         v: 'open',
        //         l: {
        //             Target: (new URL(link(data[i - 1]), window.location.origin)).toString(),
        //         },
        //     };
        // }
        //
        // sheet['!ref'] = `${xlsx.utils.encode_cell(begin)}:${xlsx.utils.encode_cell(cell)}`;
        //
        // xlsx.utils.book_append_sheet(workBook, sheet);
        //
        // xlsx.writeFile(workBook, 'result.xlsx');
    }

    async function update() {
        let query = {} as any;

        if (!schema) {
            const r = await Axios.get(`https://platform-kibana.1011.co.kr/platform-report-${name}-${date}/`);

            const s = getFields(r.data[`platform-report-${name}-${date}`].mappings._doc);
            setSchema(s);

            console.log("SCHEMA", r.data, s);
        }

        console.log("FILTER!!!", JSON.stringify(filter, null, 2));

        if (filter.length > 0) {
            query = {
                query: {
                    bool: {
                        filter: filter.map((x: any) => {
                            if (x.type === "text") {
                                if (!x.value) {
                                    return;
                                }

                                let field = x.field + ".keyword";

                                return {
                                    simple_query_string: {
                                        query: x.value,
                                        fields: [field],
                                        default_operator: 'and',
                                    }
                                };
                            } else if (x.type === "long" || x.type === "double") {
                                let field = x.field;

                                return {
                                    "range": {
                                        [field]: {
                                            'gte': x.min,
                                            'lte': x.max,
                                        }
                                    }
                                };
                            // } else if (x.type === "boolean") {
                            //     let field = x.field;
                            //
                            //     return {
                            //         "range": {
                            //             [field]: {
                            //                 'gte': x.min,
                            //                 'lte': x.max,
                            //             }
                            //         }
                            //     };
                            }
                        }).filter((x: any) => x)
                    }
                }
            }
        }

        setQuery(_.clone(query));

        // let sort = sortBy.map((x: any) => {
        //     let id = x.id;
        //
        //     if (schema[id] === "string") {
        //         id = id + ".keyword";
        //     }
        //
        //     if (fieldMap) {
        //         id = fieldMap(id);
        //     }
        //
        //     return {
        //         [id]: (x.desc ? "desc" : "asc"),
        //     };
        // });
        //
        // if (sort.length == 0) {
        //     sort = defaultSort || [];
        // }

        // query.sort = sort;
        query.size = pageSize;
        query.from = pageIndex * pageSize;

        const r = await Axios.post(url, query);

        setTotalCount(r.data.hits.total);
        setTook(r.data.took);

        console.log(r.data);

        const data = r.data.hits.hits.map((v: any) => {
            v = v._source;
            const r = {} as any;

            for (let key in mapping) {
                const val = mapping[key];

                r[key] = getValue(v, val as string);
            }

            r._link = `/`

            return r;
        });

        setData(data);
        setPageCount(Math.ceil(Math.min(r.data.hits.total, 10000) / pageSize));
    }

    useEffect(() => {
        setColumns(Object.keys(mapping).map(x => ({
            Header: x,
            accessor: x,
        })));
    }, [data]);

    useEffect(() => {
        update();
    }, []);

    function rowGetter(i: number) {
        if (i >= 0) {
            return data[i];
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        // @ts-ignore
        page,
        // @ts-ignore
        canPreviousPage,
        // @ts-ignore
        canNextPage,
        // @ts-ignore
        pageOptions,
        // @ts-ignore
        pageCount,
        // @ts-ignore
        gotoPage,
        // @ts-ignore
        nextPage,
        // @ts-ignore
        previousPage,
        // @ts-ignore
        setPageSize,
        // @ts-ignore
        state: {pageIndex, pageSize, sortBy},
    } = useTable({
        columns,
        data,
        // @ts-ignore
        initialState: {pageIndex: 0, pageSize: 50,},
        manualPagination: true,
        // @ts-ignore
        pageCount: controlledPageCount,
        manualSortBy: true,
    }, useSortBy, usePagination);

    useEffect(() => {
        update();
    }, [pageIndex, pageSize, sortBy]);

    if (data) {
        return (
            <div>
                <div>
                    {props.info && props.info()}
                    <h3>매핑</h3>
                    <div>
                        <button onClick={addMapping}>매핑추가</button>
                        <button onClick={update}>업데이트</button>
                    </div>
                    {
                        mapping && Object.keys(mapping).map((k: any, i) => {
                            const v = mapping[k];

                            return (
                                <div key={i}>
                                    <input type={"text"} value={k} onChange={(e) => {
                                        let nm = _.clone(mapping);

                                        nm = Object.keys(nm).map(ok => {
                                            let nk = ok;

                                            if (ok == k) {
                                                nk = e.target.value;
                                            }

                                            return {[nk]: nm[ok]};
                                        }).reduce((a, b) => Object.assign(a, b), {});

                                        setMapping(nm);
                                    }} onKeyDown={(e) => {
                                        if (e.keyCode == 13) {
                                            update();
                                        }
                                    }}/>
                                    <span> &lt;- </span>
                                    <select value={v} onChange={(e) => {
                                        const nm = _.clone(mapping);
                                        nm[k] = e.target.value;
                                        setMapping(nm);
                                    }}>
                                        {
                                            schema && schema.map((x: any, i: number) => {
                                                return <option key={i} value={x.name}>{x.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            );
                        })
                    }
                    <h3>필터</h3>
                    <div>
                        <button onClick={addFilter}>필터추가</button>
                        <button onClick={update}>업데이트</button>
                    </div>
                    {
                        filter.map((x: any, i: number) => {
                            return <FilterItem
                                key={i}
                                schema={schema}
                                value={filter[i]}
                                setValue={(v: any) => {
                                    const nf = _.clone(filter)

                                    nf[i] = v;

                                    setFilter(nf);
                                }}
                                update={update}
                                onRemove={() => {
                                    const nf = _.clone(filter)

                                    nf.splice(i, 1);

                                    setFilter(nf);
                                }}
                            />;
                        })
                    }
                    <span>검색 결과: {totalCount}개 ({took}ms)</span>
                    <hr/>
                    {
                        schema && <RenderChart schema={schema} mapping={mapping} url={url} query={query}/>
                    }
                    <hr/>
                </div>
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    {' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>
                    {' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>
                    {' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                    {' '}
                    <span>
                    Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                        {' '}
                    </span>
                    <span>
                    | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{width: '100px'}}
                        />
          </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[50, 100, 200, 500, 1000, 10000].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <button style={{marginLeft: '10px'}} onClick={_ => xlsxExport()}>.xlsx export (current page)
                    </button>
                </div>
                <table {...getTableProps()}>
                    <thead>
                    {// Loop over the header rows
                        headerGroups.map((headerGroup: any) => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                <th>link</th>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map((column: any) => (
                                        // Apply the header cell props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {// Render the header
                                                column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                      {column.isSorted
                          ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                          : ''}
                    </span>
                                        </th>
                                    ))}
                            </tr>
                        ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        rows.map((row: any) => {
                            // Prepare the row for display
                            prepareRow(row)
                            return (
                                // Apply the row props
                                <tr {...row.getRowProps()}>
                                    <td>
                                        <Link to={"."} target={"_blank"}>open</Link>
                                    </td>
                                    {// Loop over the rows cells
                                        row.cells.map((cell: any) => {
                                            // Apply the cell props
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return <div>Loading...</div>;
    }
}