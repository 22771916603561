import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.scss';

// import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MenuIcon from '@material-ui/icons/Menu';

// import clsx from 'clsx';
// import { Grid, Card, CardActionArea, CardContent, Button, CardMedia, CardActions, Table, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel, IconButton, SwipeableDrawer, TextField, Paper, InputBase } from '@material-ui/core';

import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

// import queryString from 'querystring';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import Signin from './Signin';

import AWS from 'aws-sdk';
import View from './View';
import Search from './Search';
import Home from './Home';

interface AppState {
  open: boolean;
  loginChecked: boolean;
}

export default class App extends Component<{}, AppState> {
  static AWS: typeof AWS;
  static session: any;
  static jwt: string;

  state: AppState = {
    loginChecked: false,
    open: false,
  };

  componentDidMount = async () => {
    if (window.location.pathname !== '/signin' && window.location.pathname !== '/frame') {
      const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID!,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
      };

      const userPool = new CognitoUserPool(poolData);

      const cognitoUser = userPool.getCurrentUser();

      if (!cognitoUser) {
        window.location.href = `${process.env.REACT_APP_COGNITO_DOMAIN}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=https://${window.location.host}/signin&response_type=code`;
      } else {
        cognitoUser.getSession((e: Error, session: CognitoUserSession) => {
          AWS.config.region = 'ap-northeast-2';
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
            Logins: {
              [`cognito-idp.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: session.getIdToken().getJwtToken(),
            },
          })

          App.jwt = session.getIdToken().getJwtToken();

          App.AWS = AWS;
          App.session = session.getIdToken().payload;

          this.setState({
            loginChecked: true,
          });
        });
      }
    } else {
      this.setState({
        loginChecked: true,
      });
    }
  }

  render = () => {
    if (this.state.loginChecked) {
      return (
        <Router>
          <Switch>
            <Route path='/view/:name/:date/:id'>
              <View />
            </Route>
            <Route path='/search/:name/:date'>
              <Search />
            </Route>
            <Route path='/signin'>
              <Signin />
            </Route>
            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </Router>
      )
    } else {
      return <div>Loading...</div>
    }
  }
} 
