import React, {Component, useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';

import {useHistory} from "react-router-dom";
import Axios from 'axios';

import ReactJson from 'react-json-view';
import proj4 from 'proj4';

const jsts = require('jsts');

const P_LL = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
const P_TM = '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs';

function toPaths(gj: any) {
    if (gj.type === "MultiPolygon") {
        // @ts-ignore
        return gj.coordinates.map((l1: any) => l1.map((l2: any) => l2.map((x: any) => new window.naver.maps.LatLng(x[0], x[1]))));
    } else {
        return [
            // @ts-ignore
            gj.coordinates.map(l => l.map(x => new window.naver.maps.LatLng(x[0], x[1]))),
        ];
    }
}

interface MapProps {
    data: any;
}

interface MapState {

}

class Map extends Component<MapProps, MapState> {
    componentDidMount = () => {
        // @ts-ignore
        const naver = window.naver;

        const map = new naver.maps.Map(this.refs.map, {
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: naver.maps.MapTypeControlStyle.DROPDOWN,
            },
        });

        const wkr = new jsts.io.WKTReader();
        const gjw = new jsts.io.GeoJSONWriter();

        let i = 0;

        for (let key in this.props.data.polys) {
            let wkt = this.props.data.polys[key];

            wkt = wkt.replace(/(\d+\.\d+) (\d+\.\d+)/g, (s: any) => {
                const [x, y] = s.split(' ');

                const r = proj4(P_TM, P_LL, [Number(x), Number(y)]);

                return `${r[1]} ${r[0]}`;
            });

            console.log("WKT", wkt);

            const geom = wkr.read(wkt);
            const gj = gjw.write(geom);
            console.log(gj);

            const paths = toPaths(gj);
            console.log(paths);

            const color = [
                '#ff0000',
                '#0000ff',
            ][Math.min(i++, 1)];

            for (let path of paths) {
                console.log("PATH", path);

                const polygon = new naver.maps.Polygon({
                    map: map,
                    paths: path,
                    fillColor: color,
                    fillOpacity: 0.5,
                    strokeColor: color,
                    strokeOpacity: 0.5,
                    strokeWeight: 3,
                    zIndex: -i * 10,
                });

                console.log("POLY", polygon);
            }

            const center = geom.getCentroid();
            console.log(center.getX(), center.getY());

            map.setCenter(new naver.maps.LatLng(center.getX(), center.getY()));
        }

        const btn = document.createElement("button");
        btn.innerText = "지적도 켜기";
        btn.id = "cadastral";

        (this.refs.map as HTMLDivElement).appendChild(btn);

        const cadastralLayer = new naver.maps.CadastralLayer();

        btn.addEventListener('click', (e) => {
            e.preventDefault();

            if (cadastralLayer.getMap()) {
                btn.innerText = "지적도 켜기";
                cadastralLayer.setMap(null);
            } else {;
                btn.innerText = "지적도 끄기";
                cadastralLayer.setMap(map);
            }
        });
    }

    render = () => {
        return <div style={{width: '500px', height: '500px'}} ref="map"></div>;
    }
}

export default function (props: any) {
    console.log("PROPS", props);

    const history = useHistory();
    const params = useParams();

    const {name, date, id} = params as any;

    const [data, setData] = useState() as any;

    console.log("HISTORY", history);
    console.log("params", params);

    useEffect(() => {
        (async () => {
            const r = await Axios.get(`https://platform-kibana.1011.co.kr/platform-report-${name}-${date}/_doc/${id}`);

            setData(r.data._source);
        })();
    }, []);

    console.log("DATA", data);

    return (
        <>
            {
                data && (
                    <>
                        <h1>{data.id}</h1>
                        {
                            data.body.map((x: any) => {
                                console.log(x);

                                let content = <></>;

                                if (x.type === "json") {
                                    content = (
                                        <ReactJson src={x.data}/>
                                    );
                                } else if (x.type === "image") {
                                    content = (
                                        <img style={{maxWidth: '500px'}} src={x.data.url}>
                                        </img>
                                    )
                                } else if (x.type === "map") {
                                    content = (
                                        <Map data={x.data}></Map>
                                    );
                                } else if (x.type === "link") {
                                    content = (
                                        <a href={x.data.url} target={"_blank"}>{x.data.url}</a>
                                    )
                                }

                                return (
                                    <section key={x.name}>
                                        <h1>{x.name}</h1>
                                        {content}
                                    </section>
                                );
                            })
                        }
                    </>
                )
            }
        </>
    );
}