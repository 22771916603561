import React, {Component, useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Axios from 'axios';
import _, {orderBy} from 'lodash';
import {useHistory} from "react-router-dom";
import {useTable, usePagination, useSortBy} from 'react-table';
import { Chart } from "react-google-charts";

import xlsx from 'xlsx';

function RenderChart(props: any) {
    const {schema, url, fieldMap, query} = props;

    const [open, setOpen] = useState() as any;
    const [types, setTypes] = useState([]) as any;
    const [type, setType] = useState() as any;
    const [chart, setChart] = useState() as any;

    const [data, setData] = useState([]) as any;

    const charts = {
        'histogram': async () => {
            console.log("URL!!", url);

            let field = open;

            if (schema[open] === "string") {
                field = field + ".keyword";
            }

            if (fieldMap) {
                field = fieldMap(field);
            }

            if (schema[open] === "string") {
                const r = await Axios.post(url, {
                    "aggs": {
                        "values": {
                            "terms": {
                                "field": field,
                                "size": 10,
                            },
                        }
                    },
                    query: query.query,
                });

                console.log(r.data);

                const { buckets } = r.data.aggregations.values;

                setChart(
                    <Chart
                        chartType={"Bar"}
                        width={'100%'}
                        height={'300px'}
                        data={[
                            ['key', 'value'],
                            ...buckets.map((x: any) => [x.key, x.doc_count]),
                            // ["기타", r.data.aggregations.values.sum_other_doc_count],
                        ]}
                        options={{
                            legend: {
                                position: 'none',
                            }
                        }}
                    />
                );
            } else {
                const r1 = await Axios.post(url, {
                    "aggs": {
                        "stats": {
                            "stats": {
                                "field": field,
                            },
                        },
                    },
                    query: query.query,
                });

                const { min, max } = r1.data.aggregations.stats;

                console.log(r1.data);

                const r = await Axios.post(url, {
                    "aggs": {
                        "values": {
                            "histogram": {
                                "field": field,
                                "interval": (max - min) / 50,
                            }
                        }
                    },
                    query: query.query,
                });

                console.log(r.data);

                const { buckets } = r.data.aggregations.values;

                setChart(
                    <Chart
                        chartType={"Bar"}
                        width={'100%'}
                        height={'300px'}
                        data={[
                            ['key', 'value'],
                            ...buckets.map((x: any) => [x.key, x.doc_count]),
                        ]}
                        options={{
                            legend: {
                                position: 'none',
                            }
                        }}
                    />
                );
            }
        }
    };

    useEffect(() => {
        if (open) {
            setTypes(Object.keys(charts));
            if (type) {
                // @ts-ignore
                charts[type]();
            }
        }
    }, [open, query]);

    useEffect(() => {
        if (type) {
            // @ts-ignore
            charts[type]();
        }
    }, [type, query])

    if (!schema) {
        return <div></div>;
    }

    let body = undefined;

    if (open) {
        body = (
            <tbody>
            <tr>
                <td>차트종류</td>
                <td colSpan={Object.keys(schema).length} rowSpan={types.length + 1}>
                    {
                        chart
                    }
                </td>
            </tr>
            {
                types.map((t: string) => {
                    if (t == type) {
                        return (
                            <tr>
                                <td onClick={_ => setType(t)} style={{backgroundColor: '#ddd'}}>{t}</td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr>
                                <td onClick={_ => setType(t)}>{t}</td>
                            </tr>
                        );
                    }
                })
            }
            </tbody>
        )
    }

    return (
        <div>
            <h3>차트</h3>
            <table>
                <thead>
                <tr>
                    <th onClick={_ => setOpen(false)}>닫기</th>
                    {
                        Object.keys(schema).map(x => {
                            if (x == open) {
                                return <th style={{backgroundColor: '#ddd'}} onClick={_ => setOpen(x)}>{x}</th>;
                            } else {
                                return <th onClick={_ => setOpen(x)}>{x}</th>;
                            }
                        })
                    }
                </tr>
                </thead>
                {body}
            </table>
        </div>
    );
}

function FilterItem(props: any) {
    const {schema, value, setValue, update, onRemove} = props;

    const [selected, setSelected] = useState(Object.keys(schema)[0]) as any;

    let input = <></>;

    switch (schema[selected]) {
        case 'number': {
            input = (
                <div style={{display: 'inline-flex', flexGrow: 1}}>
                    <span>min:</span>
                    <input
                        type={"number"}
                        value={value.min}
                        onChange={(e) => {
                            value.min = Number(e.target.value);

                            setValue(value);
                        }}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                update();
                            }
                        }}
                    />
                    <span>max:</span>
                    <input
                        type={"number"}
                        value={value.max}
                        onChange={(e) => {
                            value.max = Number(e.target.value);

                            setValue(value);
                        }}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                update();
                            }
                        }}
                    />
                </div>
            );

            break;
        }
        default: {
            input = (
                <input
                    style={{flexGrow: 1}}
                    type={"text"}
                    value={value.value}
                    onChange={(e) => {
                        value.value = e.target.value;

                        setValue(value);
                    }}
                    onKeyDown={e => {
                        if (e.keyCode == 13) {
                            update();
                        }
                    }}
                />
            );
        }
    }

    useEffect(() => {
        const s = schema[selected];

        value.field = selected;
        value.type = s;

        switch (s.type) {
            case 'number': {
                value.min = undefined;
                value.max = undefined;

                break;
            }
            default: {
                value.value = '';
            }
        }

        setValue(value);
    }, [selected]);

    return (
        <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <button onClick={onRemove}>필터제거</button>
            <select value={selected} onChange={e => setSelected(e.target.value)}>
                {
                    Object.keys(schema).map((x: any) => {
                        return <option key={x} value={x}>{x}</option>;
                    })
                }
            </select>
            {input}
        </div>
    );
}

export default function (props: any) {
    const history = useHistory();

    const {url, link, transform, defaultSort, fieldMap} = props;

    let [data, setData] = useState([]) as any;
    let [columns, setColumns] = useState([]) as any;
    let [schema, setSchema] = useState() as any;
    let [query, setQuery] = useState("") as any;
    let [totalCount, setTotalCount] = useState(0) as any;
    let [took, setTook] = useState(0) as any;
    let [filter, setFilter] = useState([]) as any;

    const [controlledPageCount, setPageCount] = React.useState(0);

    function addFilter() {
        const v = {
            field: 'id',
            type: 'string',
            value: '',
        };

        setFilter([...filter, v]);
    }

    async function xlsxExport() {
        const workBook = xlsx.utils.book_new();

        const sheet = xlsx.utils.json_to_sheet(data);

        let [begin, end] = sheet['!ref']!.split(':') as any;
        begin = xlsx.utils.decode_cell(begin);
        end = xlsx.utils.decode_cell(end);

        const cell = {
            c: end.c + 1,
            r: 0,
        };

        sheet[xlsx.utils.encode_cell(cell)] = {
            v: 'link',
            t: 's',
        };

        for (let i = 1; i <= end.r; ++i) {
            cell.r = i;

            const a = xlsx.utils.encode_cell(cell);

            sheet[a] = {
                v: 'open',
                l: {
                    Target: (new URL(link(data[i - 1]), window.location.origin)).toString(),
                },
            };
        }

        sheet['!ref'] = `${xlsx.utils.encode_cell(begin)}:${xlsx.utils.encode_cell(cell)}`;

        xlsx.utils.book_append_sheet(workBook, sheet);

        xlsx.writeFile(workBook, 'result.xlsx');
    }

    async function update() {
        let query = {} as any;

        console.log("FILTER!!!", JSON.stringify(filter, null, 2));

        if (filter.length > 0) {
            query = {
                query: {
                    bool: {
                        filter: filter.map((x: any) => {
                            if (x.type === "string") {
                                if (!x.value) {
                                    return;
                                }

                                let field = x.field;

                                // if (schema[field] === "string") {
                                //     field = field + ".keyword"
                                // }

                                if (fieldMap) {
                                    field = fieldMap(field);
                                }

                                return {
                                    simple_query_string: {
                                        query: x.value,
                                        fields: [field],
                                        default_operator: 'and',
                                    }
                                };
                            } else if (x.type === "number") {
                                let field = x.field;

                                if (fieldMap) {
                                    field = fieldMap(field);
                                }

                                return {
                                    "range": {
                                        [field]: {
                                            'gte': x.min,
                                            'lte': x.max,
                                        }
                                    }
                                }
                            }
                        }).filter((x: any) => x)
                    }
                }
            }
        }

        setQuery(_.clone(query));

        let sort = sortBy.map((x: any) => {
            let id = x.id;

            if (schema[id] === "string") {
                id = id + ".keyword";
            }

            if (fieldMap) {
                id = fieldMap(id);
            }

            return {
                [id]: (x.desc ? "desc" : "asc"),
            };
        });

        if (sort.length == 0) {
            sort = defaultSort || [];
        }

        query.sort = sort;
        query.size = pageSize;
        query.from = pageIndex * pageSize;

        const r = await Axios.post(url, query);

        setTotalCount(r.data.hits.total);
        setTook(r.data.took);

        setData(r.data.hits.hits.map((x: any) => transform ? transform(x._source) : x._source));
        setPageCount(Math.ceil(Math.min(r.data.hits.total, 10000) / pageSize));
    }

    useEffect(() => {
        update();
    }, []);

    if (columns.length == 0 && data.length > 0) {
        const keys = _(data.map((x: any) => Object.keys(x))).flatten().uniq();

        columns = keys.map(x => ({
            Header: x,
            accessor: x,
        }));

        schema = keys.map(x => {
            return {[x]: (typeof data[0][x])};
        }).reduce((a, b) => Object.assign(a, b), {});

        setColumns(columns);
        setSchema(schema);
    }

    function rowGetter(i: number) {
        if (i >= 0) {
            return data[i];
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        // @ts-ignore
        page,
        // @ts-ignore
        canPreviousPage,
        // @ts-ignore
        canNextPage,
        // @ts-ignore
        pageOptions,
        // @ts-ignore
        pageCount,
        // @ts-ignore
        gotoPage,
        // @ts-ignore
        nextPage,
        // @ts-ignore
        previousPage,
        // @ts-ignore
        setPageSize,
        // @ts-ignore
        state: {pageIndex, pageSize, sortBy},
    } = useTable({
        columns,
        data,
        // @ts-ignore
        initialState: {pageIndex: 0, pageSize: 50,},
        manualPagination: true,
        // @ts-ignore
        pageCount: controlledPageCount,
        manualSortBy: true,
    }, useSortBy, usePagination);

    useEffect(() => {
        update();
    }, [pageIndex, pageSize, sortBy]);

    if (data) {
        return (
            <div>
                <div>
                    {props.info && props.info()}
                    <h3>필터</h3>
                    <div>
                        <button onClick={addFilter}>필터추가</button>
                    </div>
                    {
                        filter.map((x: any, i: number) => {
                            return <FilterItem
                                key={i}
                                schema={schema}
                                value={filter[i]}
                                setValue={(v: any) => {
                                    const nf = _.clone(filter)

                                    nf[i] = v;

                                    setFilter(nf);
                                }}
                                update={update}
                                onRemove={() => {
                                    const nf = _.clone(filter)

                                    nf.splice(i, 1);

                                    setFilter(nf);
                                }}
                            />;
                        })
                    }
                    <span>검색 결과: {totalCount}개 ({took}ms)</span>
                    < hr/>
                    <RenderChart schema={schema} url={url} fieldMap={fieldMap} query={query}/>
                    <hr/>
                </div>
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    {' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>
                    {' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>
                    {' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                    {' '}
                    <span>
            Page{' '}
                        <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
                    <span>
            | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{width: '100px'}}
                        />
          </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[50, 100, 200, 500, 1000, 10000].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <button style={{marginLeft: '10px'}} onClick={_ => xlsxExport()}>.xlsx export (current page)
                    </button>
                </div>
                <table {...getTableProps()}>
                    <thead>
                    {// Loop over the header rows
                        headerGroups.map((headerGroup: any) => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                <th>link</th>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map((column: any) => (
                                        // Apply the header cell props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {// Render the header
                                                column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                      {column.isSorted
                          ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                          : ''}
                    </span>
                                        </th>
                                    ))}
                            </tr>
                        ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        rows.map((row: any) => {
                            // Prepare the row for display
                            prepareRow(row)
                            return (
                                // Apply the row props
                                <tr {...row.getRowProps()}>
                                    <td>
                                        <Link to={link(row.original)} target={"_blank"}>open</Link>
                                    </td>
                                    {// Loop over the rows cells
                                        row.cells.map((cell: any) => {
                                            // Apply the cell props
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return <div>Loading...</div>;
    }
}