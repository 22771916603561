import React, {Component, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Axios from 'axios';
import _ from 'lodash';
import {useHistory} from "react-router-dom";
import {useTable, usePagination} from 'react-table';

import SearchPage from './SearchPage';
import SearchPageV2 from "./SearchPageV2";

export default function (props: any) {
    const {name, date} = useParams();

    const [reportInfo, setReportInfo] = useState() as any;
    const [saving, setSaving] = useState(false) as any;

    useEffect(() => {
        (async () => {
            const r = await Axios.get(`https://platform-kibana.1011.co.kr/platform-report/_doc/platform-report-${name}-${date}`);

            setReportInfo(r.data._source);
        })();
    }, []);

    const url = `https://platform-kibana.1011.co.kr/platform-report-${name}-${date}/_search`;

    function link(v: any) {
        const data = v;

        return `/view/${name}/${date}/${data.id}`;
    }

    function transform(x: any) {
        return {id: x.id, ...x.head};
    }

    function fieldMap(x: string) {
        if (x === "id.keyword") {
            return "id";
        } else {
            return "head." + x;
        }
    }

    function info() {
        if (reportInfo) {
            const info = `전체 ${reportInfo.totalCount}개 결과`;

            const downloadLink = <a
                href={`https://teneleven-platform-data-lake-report.s3.ap-northeast-2.amazonaws.com/${name}/${date}/result.zip`}>전체
                결과 다운로드 받기(zip)</a>;

            const comment = <input type="text" defaultValue={reportInfo.comment} style={{width: '500px'}}
                                   onKeyDown={e => {
                                       if (e.keyCode == 13) {
                                           // @ts-ignore
                                           const value = e.target.value;

                                           (async () => {
                                               console.log("UPDATE COMMENT!!", value);

                                               setSaving(true);

                                               const r = await Axios.post(`https://platform-kibana.1011.co.kr/platform-report/_doc/platform-report-${name}-${date}/_update?refresh=wait_for`, {
                                                   "doc": {
                                                       "comment": value,
                                                   },
                                               });

                                               setSaving(false);
                                           })();
                                       }
                                   }}/>

            const [y, m, d, hh, mm, ss] = date!.split('.');
            const dateString = `${y}년 ${m}월 ${d}일 ${hh}시 ${mm}분`;

            return (
                <>
                    <div>
                        <h2>{name} / {dateString} 결과</h2>
                        <p>{reportInfo.description}</p>
                        <p>{info} / {downloadLink}</p>
                        <p>comment: {comment} {saving && <span>saving...</span>}</p>
                    </div>
                    <hr/>
                </>
            );
        } else {
            return <div></div>;
        }
    }

    if (reportInfo && reportInfo.version == 'v2') {
        return <SearchPageV2 name={name} date={date} reportInfo={reportInfo} info={info} />;
    } else if (reportInfo) {
        return <SearchPage url={url} link={link} info={info} transform={transform} fieldMap={fieldMap}
                           defaultSort={[{"id": "asc"}]}/>
    } else {
        return <div></div>;
    }
}